.container .carousel {
  width: 100%;
}

#container .carousel .item {
  display: block;
  border-radius: 7px;
}

.container .carousel .slick-slide {
  padding: 0 10px;
}