#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-end;
}

#header .header1 .container .title {
    font-weight: normal;
    color: white;
    font-size: 70px;
}

#header .header1 .container .title span {
    font-weight: bold;
    background-color: #C11822;
    border-radius: 7px;
}

#header .header1 .container .text {
    color: white;
    text-align: center;
    font-size: 30px;
    font-weight: normal;
}

#header .header1 .container #ctaWpp {
    margin: 0 auto;
}

#header .header2 .container .text {
    text-align: center;
}

#header .header2 .container #ctaWpp {
    margin: 0 auto;
}

#header .header3 {
    background: url("../../assets/header3bg.png") no-repeat center center;
    background-size: cover;
}

#header .header3 .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
}

#header .header3 .container .left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header3 .container .left .title {
    color: white;
    text-align: left;
}

#header .header3 .container .left .mobile {
    display: none;
}

#header .header3 .container .left .text {
    color: white;
}

#header .header3 .container .left #ctaWpp {
    width: 70%;
}

#header .header3 .container .right img {
    border-radius: 7px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 70vh;
    }

    #header .header1 .container .title {
        font-size: 50px;
    }

    #header .header1 .container .text {
        font-size: 23px;
    }

    #header .header2 .container .mobile {
        display: flex;
        flex-direction: column;
    }

    #header .header3 {
        background: url("../../assets/mobile/header3bg.png") no-repeat center center;
        background-size: cover;
    }

    #header .header3 .container {
        grid-template-columns: 1fr;
    }

    #header .header3 .container .left .title {
        text-align: center;
    }

    #header .header3 .container .left .mobile {
        display: block;
    }

    #header .header3 .container .left .mobile img {
        border-radius: 7px;
        width: 70%;
    }

    #header .header3 .container .left .text {
        text-align: center;
    }

    #header .header3 .container .left #ctaWpp {
        margin: 0 auto;
        width: 60%;
    }

    #header .header3 .container .right {
        display: none;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container .title {
        font-size: 30px;
    }

    #header .header1 .container .text {
        font-size: 16px;
    }

    #header .header3 .container .left #ctaWpp {
        width: 75%;
    }
}