#menu {
    border-bottom: 5px solid #C11822;
}

#menu .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem 0;
}

#menu .container .left {
    display: grid;
    grid-template-columns: 5% 1fr;
    align-items: center;
    gap: .5rem;
}

#menu .container .left figure img {
    display: block;
}

#menu .container .left .text {
    color: #302F2F;
    font-weight: bold;
    font-size: 14px;
}

#menu .container .left .text a {
    color: inherit;
}

#menu .container .right {
    width: 70%;
    margin-left: auto;
}

#menu .container .logo {
    position: absolute;
    left: 45%;
    top: 0;
    width: 10%;
}

#menu .container .right {
    display: grid;
    grid-template-columns: 1fr 50%;
    align-items: center;
}

#menu .container .right .text {
    color: #302F2F;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container {
        padding: 2rem 0;
    }

    #menu .container .left {
        display: none;
    }

    #menu .container .right {
        display: none;
    }

    #menu .container .logo {
        left: 40%;
        width: 20%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container .logo {
        left: 35%;
        width: 30%;
    }
}