#footer .footer1 .container {
    display: grid;
    grid-template-columns: 60% 1fr;
}

#footer .footer1 .container .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

#footer .footer1 .container .content .logo {
    text-align: left;
}

#footer .footer1 .container .content .logo img {
    width: 90%;
}

#footer .footer1 .container .content .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#footer .footer1 .container .content .item .item_title {
    color: #346C83;
    font-weight: bold;
}

#footer .footer1 .container .content .item .item_text {
    color: #302F2F;
    font-size: 16px;
    max-width: 200px;
}

#footer .footer1 .container .content .item .ctas {
    display: flex;
    flex-direction: column;
}

#footer .footer1 .container .content .item .ctas .cta {
    display: grid;
    grid-template-columns: 8% 1fr;
    gap: .2rem;
}

#footer .footer2 {
    background-color: #C11822;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#footer .footer2 .container .text {
    color: white;
    font-size: 16px;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container {
        grid-template-columns: 1fr;
    }

    #footer .footer1 .container .content {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    #footer .footer1 .container .content .logo {
        text-align: center;
    }

    #footer .footer1 .container .content .logo img {
        width: 35%;
    }

    #footer .footer1 .container .content .item .item_text {
        max-width: 300px;
        font-size: 15px;
    }

    #footer .footer1 .container .content .item .ctas .cta {
        grid-template-columns: 3% 1fr;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: 1rem;
    }

    #footer .footer2 .container .text {
        text-align: center;
        font-size: 15px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container .content .item .item_text {
        font-size: 14px;
    }

    #footer .footer2 .container .text {
        font-size: 14px;
    }
}