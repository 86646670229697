#ctaTel {
    background-color: #346C83;
    font-weight: 700;
    padding: .6rem .5rem;
    width: 80%;
    height: fit-content;
    border-radius: 10rem;
}

#ctaTel a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaTel a figure {
    width: 10%;
}

#ctaTel a figure img {
    width: 100%;
    display: block;
}

#ctaTel p {
    color: white;
    font-weight: 700;
    font-size: 14px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaTel {
        width: 60%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaTel {
        width: 75%;
    }
}