#about .about1 {
  background-color: #EEF6FB;
}

#about .about1 .container .text {
  margin-top: -1rem;
  text-align: center;
}

#about .about1 .container #ctaWpp {
  margin: 0 auto;
}

#about .about2 .container .text {
  margin-top: -1rem;
  text-align: center;
  font-weight: bold;
}

#about .about2 .container .text.text2 {
  margin-top: 1rem;
}

#about .about2 .container #ctaWpp {
  margin: 0 auto;
}

#about .about3 {
  background: url("../../assets/about3.png") no-repeat center center;
  background-size: cover;
}

#about .about3 .container .title {
  color: #FFB84F;
}

#about .about3 .container .gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 4rem;
  margin: 0 auto;
}

#about .about3 .container .gallery li {
  color: white;
  list-style-type: disc;
}

#about .about3 .container #ctaWpp {
  margin: 0 auto;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container .mobile {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  #about .about2 .container .text {
    margin-top: -1rem;
    text-align: center;
    font-weight: bold;
  }


  #about .about3 .container .gallery {
    grid-template-columns: repeat(2, 1fr);
    gap: 0 2rem;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}